.ant-steps-item-container {
  padding-bottom: 15px !important;
}

.section {
  box-shadow: 5px 5px 5px #dddddd;
  //border-color: rgba(93, 34, 161, 0.3);
  border-radius: 15px;
}

.metric-info {
  cursor: pointer;

}

.metric-info:hover {
  box-shadow: 5px 5px 10px #dddddd;
  background-color: #efefef;
}

.past-scroller {
  padding-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  max-height: 150px;
}
.past-event-card {
  display: inline-block;
  width: 200px;
  height: 100px;
  margin-right: 15px;
  border-radius: 15px;
}

.header_card {
  width: 240px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
  margin-right: 15px !important;
  border-right-color: rgba(0, 0, 0, 0.45) !important;
  background-color: #f6f5f5;
}

.ant-card-meta-title {
  font-weight: bold;
  color: #5d22a1 !important;
  font-size: 14px !important;
}
.ant-statistic-title {
  font-weight: bold;
  color: #5d22a1 !important;
  font-size: 14px !important;
}

.analyzeModal {
  width: 75% !important;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #5d22a1;
}

.ant-steps-item-subtitle {
  color: #000 !important;
}


@media only screen and (max-width: 1300px) {
  .cardWrapSuggest {
    background-position: right 0vw top 0px;
    white-space: nowrap !important;
    overflow-x: scroll !important;
  }
}
@media only screen and (max-width: 800px) {
  .analyzeModal {
    width: 90% !important;
  }
}
@media only screen and (max-width: 600px) {
  .analyzeModal {
    width: 100% !important;
  }

  .antd-pro-charts-chart-card-chartTop {
    padding-bottom: 8vw !important;
  }
}

@primary-color: #5D22A1;@highlight-color: #6AE9B2;