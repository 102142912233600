.info-icon {
  color: #6ae9b2;
  margin-right: 5px;
}

.engage-recommend {
  box-shadow: 5px 5px 5px #dddddd;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  height: 650px;
  overflow: hidden;
}

.recommend-card {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 8px !important;
}

.thumbnails {
  border-radius: 8px;
  height: 50px;
  max-width: 80px;
  margin-right: 10px;
  cursor: pointer;
}

.activity-card {
  height: 100px;
  overflow: hidden;
  vertical-align: bottom;
  border-radius: 8px !important;
  background-color: #182135;//5256BE;
}

.engage-activityPic {
  height: 80px;
  padding-top: 30px;
  object-fit: scale-down !important;
}

.all-event-modal {
  min-width: 60%;
}

.engage-category-description {
  font-size: 14px;
  color: #868784;
}

.activities-parent-card {
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  borderradius: 8px;
}

.activities-doodle-btn {
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #eb5d74 !important;
}

.activities-doodle-btn:hover {
  color: #ffffff !important;
  background: #eb5d74;
  border: 1px solid #eb5d74 !important;
}
.engage-spotlight .ant-radio-button-wrapper {
  color: #eb5d74 !important;
  border: 1px solid #eb5d74 !important;
}

.engage-spotlight .ant-radio-button-wrapper:hover {
  color: #ffffff !important;
}

.engage-spotlight .ant-radio-group-solid .ant-radio-button-wrapper-checked {
  background: #eb5d74 !important;
  border: 1px solid #eb5d74 !important;
  color: white !important;
}
.engage-spotlight .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  display: none;
}
.engage-spotlight .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.async-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.tool-icon {
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  max-width: 80px;
}

.tool-container {
  background-color: #FFFFFF;
  padding: 16px;
  margin-top: 20px;
  border-radius: 8px;
}

.tool-step {
  background-color: #5256BE;
  border-radius: 8px;
  padding: 16px 20px;
  height: 100%;
}

.tool-generator {
  height: 100%;
  background-color: #EB5D74;
  border-radius: 8px;
  padding: 16px 20px;
}

.tool-bubble {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  color: #ffffff;
  background-color: #6AE9B2;
}

@media only screen and (max-width: 1199px) {
  .thumbnails {
    width: auto;
    height: 30px;
    max-width: none;
  }
  .card {
    height: 180px;
  }
}

/* Recommend column drops down here*/
@media only screen and (max-width: 991px) {
  .engage-recommend {
    height: 220px;
  }
  .recommend-card {
    width: calc(90% - 1px);
    height: 130px;
  }
}
@media only screen and (max-width: 575px) {
  .eventPopup {
    width: 50vw !important;
    padding-top: 2px !important;
  }
  .engage-recommend {
    height: 220px;
  }
  .engage-mobile-scroll {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .recommend-card {
    height: 120px;
    width: 100px;
  }
  .engage-cardWrap {
    height: 130px;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll !important;
  }
  .card {
    height: 180px;
    overflow: hidden;
    display: inline-block;
    border-radius: 15px !important;
  }
  .engage-activityPic {
    height: 60px !important; /*was 190px*/
  }
}

@primary-color: #5D22A1;@highlight-color: #6AE9B2;