.label-white label {
  color: white;
}

.question-white {
  color: white;
}

.question-white svg {
  color: white;
}

.white-order {
  color: white;
}

.white-order svg {
   color: white;
}
@primary-color: #5D22A1;@highlight-color: #6AE9B2;