.engage-spotlight {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.event-cover {
  border-radius: 8px;
  min-width: 200px;
  max-height: 300px;
  max-width: 100%;
}

.event-video {
  border-radius: 8px;
  border: 0;
  width: 100%;
  height: calc(40vw * 0.56);
  max-width: 700px;
  max-height: 360px;
}

.doodle-leaderboard .ant-table-title {
  padding-top: 5px;
  padding-bottom: 0px;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #eb5d74;
}
.doodle-leaderboard .ant-table-thead {
  display: none;
}
@media only screen and (max-width: 991px) {
  .event-video {
    height: calc(60vw * 0.56);
  }
}

@media only screen and (max-width: 767px) {
  .event-video {
    height: calc(80vw * 0.56);
  }
  .doodle-collapse {
    flex-direction: column-reverse
  }
}

@media only screen and (max-width: 575px) {

}

@primary-color: #5D22A1;@highlight-color: #6AE9B2;