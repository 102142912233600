.host-card {
  width: 330px;
  height: 220px;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  margin: 10px 20px 10px 0px !important;  
  border-radius: 15px !important;
}

.host-card-img {
  object-fit: cover;
  height: 120px
}

.eventPopup {
  width: 50vw !important;
  padding-top: 2px !important;
}

@media only screen and (max-width: 575px) {
  .eventPopup {
    width: auto !important;
  }
  .host-card {
    width: 240px;
    height: 250px;
    overflow: hidden;
    display: inline-block;
    margin-right: 15px !important;
    border-radius: 15px !important;
  }
  .host-card-img {
    height: 140px
  }
}
@primary-color: #5D22A1;@highlight-color: #6AE9B2;