.settingsInput {
  width: 80%;
  max-width: 350px;
}
.logout {
  float: right;
}

@media only screen and (max-width: 600px) {
  .settingsInput {
    width: 100%;
    max-width: 200px;
  }
  .logout {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

@primary-color: #5D22A1;@highlight-color: #6AE9B2;