@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0;
  font-family: sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dashboard-dashSection {
  background-color: white !important;
  border-radius: 8px;
  overflow-y: hidden;
  position: relative;
  border: 1px solid #F0F0F0;
}
.dashboard-metric-list {
  height: 350px;
}
.ant-page-header-heading-title {
  color: #333333 !important;
}
.metric-icon {
  margin: 0 0 0 24px;
  font-size: 24px;
  color: #5d22a1;
}
.engagement-metric {
  margin: 0 0 0 24px;
  font-size: 24px;
}
.dashboard-metrics-filter {
  background-color: #F9FBFC !important;
  border: 1px solid #F0F0F0 !important;
}
.dashboard-suggested-card {
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  padding: 0px !important;
  background-color: #FFFFFF;
  cursor: pointer;
}
.dashboard-suggested-card .ant-card-body {
  padding: 10px !important;
}
.dashboard-comingUp .ant-card-body {
  padding: 16px !important;
}
.dashboard-comingUpcard-scroll {
  min-height: 200px;
  max-height: 350px;
  overflow-y: scroll;
}
.dashboard-comingUpcard {
  min-height: 200px;
  max-height: 350px;
  overflow-y: hidden;
}
@media only screen and (max-width: 500px) {
  .dashboard-metric-list {
    height: auto;
  }
}

.engage-spotlight {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}
.event-cover {
  border-radius: 8px;
  min-width: 200px;
  max-height: 300px;
  max-width: 100%;
}
.event-video {
  border-radius: 8px;
  border: 0;
  width: 100%;
  height: calc(40vw * 0.56);
  max-width: 700px;
  max-height: 360px;
}
.doodle-leaderboard .ant-table-title {
  padding-top: 5px;
  padding-bottom: 0px;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #eb5d74;
}
.doodle-leaderboard .ant-table-thead {
  display: none;
}
@media only screen and (max-width: 991px) {
  .event-video {
    height: calc(60vw * 0.56);
  }
}
@media only screen and (max-width: 767px) {
  .event-video {
    height: calc(80vw * 0.56);
  }
  .doodle-collapse {
    flex-direction: column-reverse;
  }
}

.label-white label {
  color: white;
}
.question-white {
  color: white;
}
.question-white svg {
  color: white;
}
.white-order {
  color: white;
}
.white-order svg {
  color: white;
}

.info-icon {
  color: #6ae9b2;
  margin-right: 5px;
}
.engage-recommend {
  box-shadow: 5px 5px 5px #dddddd;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  height: 650px;
  overflow: hidden;
}
.recommend-card {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 8px !important;
}
.thumbnails {
  border-radius: 8px;
  height: 50px;
  max-width: 80px;
  margin-right: 10px;
  cursor: pointer;
}
.activity-card {
  height: 100px;
  overflow: hidden;
  vertical-align: bottom;
  border-radius: 8px !important;
  background-color: #182135;
}
.engage-activityPic {
  height: 80px;
  padding-top: 30px;
  object-fit: scale-down !important;
}
.all-event-modal {
  min-width: 60%;
}
.engage-category-description {
  font-size: 14px;
  color: #868784;
}
.activities-parent-card {
  margin-top: 20px;
  border: 1px solid #f0f0f0;
  borderradius: 8px;
}
.activities-doodle-btn {
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #eb5d74 !important;
}
.activities-doodle-btn:hover {
  color: #ffffff !important;
  background: #eb5d74;
  border: 1px solid #eb5d74 !important;
}
.engage-spotlight .ant-radio-button-wrapper {
  color: #eb5d74 !important;
  border: 1px solid #eb5d74 !important;
}
.engage-spotlight .ant-radio-button-wrapper:hover {
  color: #ffffff !important;
}
.engage-spotlight .ant-radio-group-solid .ant-radio-button-wrapper-checked {
  background: #eb5d74 !important;
  border: 1px solid #eb5d74 !important;
  color: white !important;
}
.engage-spotlight .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  display: none;
}
.engage-spotlight .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.async-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}
.tool-icon {
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  max-width: 80px;
}
.tool-container {
  background-color: #FFFFFF;
  padding: 16px;
  margin-top: 20px;
  border-radius: 8px;
}
.tool-step {
  background-color: #5256BE;
  border-radius: 8px;
  padding: 16px 20px;
  height: 100%;
}
.tool-generator {
  height: 100%;
  background-color: #EB5D74;
  border-radius: 8px;
  padding: 16px 20px;
}
.tool-bubble {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  color: #ffffff;
  background-color: #6AE9B2;
}
@media only screen and (max-width: 1199px) {
  .thumbnails {
    width: auto;
    height: 30px;
    max-width: none;
  }
  .card {
    height: 180px;
  }
}
/* Recommend column drops down here*/
@media only screen and (max-width: 991px) {
  .engage-recommend {
    height: 220px;
  }
  .recommend-card {
    width: calc(90% - 1px);
    height: 130px;
  }
}
@media only screen and (max-width: 575px) {
  .eventPopup {
    width: 50vw !important;
    padding-top: 2px !important;
  }
  .engage-recommend {
    height: 220px;
  }
  .engage-mobile-scroll {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .recommend-card {
    height: 120px;
    width: 100px;
  }
  .engage-cardWrap {
    height: 130px;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll !important;
  }
  .card {
    height: 180px;
    overflow: hidden;
    display: inline-block;
    border-radius: 15px !important;
  }
  .engage-activityPic {
    height: 60px !important;
    /*was 190px*/
  }
}

.host-card {
  width: 330px;
  height: 220px;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  margin: 10px 20px 10px 0px !important;
  border-radius: 15px !important;
}
.host-card-img {
  object-fit: cover;
  height: 120px;
}
.eventPopup {
  width: 50vw !important;
  padding-top: 2px !important;
}
@media only screen and (max-width: 575px) {
  .eventPopup {
    width: auto !important;
  }
  .host-card {
    width: 240px;
    height: 250px;
    overflow: hidden;
    display: inline-block;
    margin-right: 15px !important;
    border-radius: 15px !important;
  }
  .host-card-img {
    height: 140px;
  }
}

.settingsInput {
  width: 80%;
  max-width: 350px;
}
.logout {
  float: right;
}
@media only screen and (max-width: 600px) {
  .settingsInput {
    width: 100%;
    max-width: 200px;
  }
  .logout {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.ant-steps-item-container {
  padding-bottom: 15px !important;
}
.section {
  box-shadow: 5px 5px 5px #dddddd;
  border-radius: 15px;
}
.metric-info {
  cursor: pointer;
}
.metric-info:hover {
  box-shadow: 5px 5px 10px #dddddd;
  background-color: #efefef;
}
.past-scroller {
  padding-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  max-height: 150px;
}
.past-event-card {
  display: inline-block;
  width: 200px;
  height: 100px;
  margin-right: 15px;
  border-radius: 15px;
}
.header_card {
  width: 240px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
  margin-right: 15px !important;
  border-right-color: rgba(0, 0, 0, 0.45) !important;
  background-color: #f6f5f5;
}
.ant-card-meta-title {
  font-weight: bold;
  color: #5d22a1 !important;
  font-size: 14px !important;
}
.ant-statistic-title {
  font-weight: bold;
  color: #5d22a1 !important;
  font-size: 14px !important;
}
.analyzeModal {
  width: 75% !important;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #5d22a1;
}
.ant-steps-item-subtitle {
  color: #000 !important;
}
@media only screen and (max-width: 1300px) {
  .cardWrapSuggest {
    background-position: right 0vw top 0px;
    white-space: nowrap !important;
    overflow-x: scroll !important;
  }
}
@media only screen and (max-width: 800px) {
  .analyzeModal {
    width: 90% !important;
  }
}
@media only screen and (max-width: 600px) {
  .analyzeModal {
    width: 100% !important;
  }
  .antd-pro-charts-chart-card-chartTop {
    padding-bottom: 8vw !important;
  }
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.pageMargin {
  margin: 0 24px !important;
}
.logo-sider {
  text-align: center;
  height: 100px;
  padding-top: 10px;
  padding-bottom: 40px;
}
.ant-layout {
  background-color: #f5f5f5 !important;
}
.ant-layout-header {
  height: auto !important;
  padding-bottom: 2px !important;
  background-color: #f5f5f5 !important;
}
.ant-page-header-heading-sub-title {
  overflow: visible  !important;
  white-space: normal !important;
}
.main-footer {
  text-align: left;
  padding: 10px 24px;
}
@media only screen and (max-width: 850px) {
  .ant-page-header-heading-sub-title {
    display: none;
  }
}

