.dashboard-dashSection {
  background-color: white !important;
  border-radius: 8px;
  overflow-y: hidden;
  position: relative;
  border: 1px solid #F0F0F0;
}

.dashboard-metric-list {
  height: 350px;
}

.ant-page-header-heading-title {
  color: #333333 !important;
}

.metric-icon {
  margin: 0 0 0 24px;
  font-size: 24px;
  color: #5d22a1;
}

.engagement-metric {
  margin: 0 0 0 24px;
  font-size: 24px;
}

.dashboard-metrics-filter{
  background-color: #F9FBFC !important;
  border: 1px solid #F0F0F0 !important;
}

.dashboard-suggested-card{
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  padding:0px !important;
  background-color: #FFFFFF;
  cursor: pointer;

}

.dashboard-suggested-card .ant-card-body{
  padding:10px !important;
}

.dashboard-comingUp .ant-card-body{
  padding:16px !important;
}

.dashboard-comingUpcard-scroll{
  min-height: 200px;
  max-height: 350px;
  overflow-y:scroll;
}

.dashboard-comingUpcard{
  min-height: 200px;
  max-height: 350px;
  overflow-y: hidden;
}


@media only screen and (max-width: 500px) {

  .dashboard-metric-list {
    height: auto;
  }

}

@primary-color: #5D22A1;@highlight-color: #6AE9B2;