#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.pageMargin {
  margin: 0 24px !important;
}

.logo-sider {
  text-align: center;
  height: 100px;
  padding-top: 10px;
  padding-bottom: 40px;
}

.ant-layout {
  background-color: #f5f5f5 !important;
}

.ant-layout-header {
  height: auto !important;
  padding-bottom: 2px !important;
  background-color: #f5f5f5 !important;
}

.ant-page-header-heading-sub-title {
  overflow:visible  !important;
  white-space: normal !important;
}

.main-footer {
  text-align: left;
  padding: 10px 24px;
}

@media only screen and (max-width: 850px) {
  .ant-page-header-heading-sub-title {
    display: none;
  }
}

@primary-color: #5D22A1;@highlight-color: #6AE9B2;